import React from 'react'
import Signup from '../components/auth/Signup'

export default function SignuPage() {
  return (
    <div>
        <Signup/>
    </div>
  )
}
