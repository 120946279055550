import React from 'react'
import Login from '../components/auth/Login'


export default function LoginPage() {
  return (
    <div>
        <Login/>
    </div>
  )
}
