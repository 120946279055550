import React from 'react';

function Church1() {
  return (
    <div>
      HELLO
    </div>
  );
}

export default Church1;
